export default defineNuxtRouteMiddleware(to => {
  const { isMobile } = useAppBreakpoints()

  const isProfilePage = computed(() => {
    return to.name === 'profile'
  })

  if (isProfilePage.value) {
    if (isMobile) {
      return navigateTo({ name: 'profile-main' })
    } else {
      return navigateTo({ name: 'profile-general' })
    }
  }

  const isProfileMainPage = computed(() => {
    return to.name === 'profile-main'
  })

  if (isProfileMainPage.value && !isMobile) {
    return navigateTo({ name: 'profile-general' })
  }
})
